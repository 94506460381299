import React, { Suspense, useEffect } from "react";
import "./index.css";
import { persistor, store } from "./reduxToolkit/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./routes/components/Router";
import ClearCache from "react-clear-cache";
import getBrandName from "./utils/getBrandName";
// import BrandingProvider from "./branding/provider/BrandingProvider";
// import BrandingProviderWPP from "./branding/provider/BrandingProviderWPP";
import { brandConstants } from "./utils/brandConstants";
import FallBackPage from "./common/components/FallBackPage/FallBackPage";

const BrandingProvider = React.lazy(() =>
  import("./branding/provider/BrandingProvider")
);
const BrandingProviderWPP = React.lazy(() =>
  import("./branding/provider/BrandingProviderWPP")
);

const WPPApp = () => (
  <Suspense fallback={<FallBackPage />}>
    <BrandingProviderWPP>
      <Router />
    </BrandingProviderWPP>
  </Suspense>
);

const DefaultApp = () => (
  <Suspense fallback={<FallBackPage />}>
    <BrandingProvider>
      <Router />
    </BrandingProvider>
  </Suspense>
);

const App = () => {
  let brandName = getBrandName();

  useEffect(() => {
    localStorage.removeItem("brandName");
    localStorage.removeItem("superBrandId");
    const brandName = getBrandName();
    let isBrandClassNameAddedToBody =
      document.body.classList.contains(brandName);
    if (!isBrandClassNameAddedToBody) {
      document.body.classList.add(brandName);
    }
  }, []);

  // console.log(brandName, "Brandname cs");

  return (
    <ClearCache auto={true}>
      {({ isLatestVersion, emptyCacheStorage }) => {
        if (!isLatestVersion) {
          emptyCacheStorage();
        }
        return (
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              {brandName == brandConstants.WPP &&
              process.env.NODE_ENV === "production" ? (
                <WPPApp />
              ) : (
                <DefaultApp />
              )}
            </PersistGate>
          </Provider>
        );
      }}
    </ClearCache>
  );
};
export default App;
